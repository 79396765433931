import React, {useState, useEffect, useMemo, useCallback} from "react";
import DeleteButton from "../components/DeleteButton";

const Pomodoro: React.FC = () => {
  const [time, setTime] = useState(1500); // 25 minutes in seconds
  const [isRunning, setIsRunning] = useState(false);
  const [newTask, setNewTask] = useState<string>("");
  const [tasks, setTasks] = useState<{name: string; completed: boolean}[]>([]);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [taskEditText, setTaskEditText] = useState<string>("");
  const [ambientSound, setAmbientSound] = useState("Ocean Sound 1");
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioInstance, setAudioInstance] = useState<HTMLAudioElement | null>(null);
  const [activeMode, setActiveMode] = useState<string | null>(null);

  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
  
    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (time === 0) {
      setIsRunning(false);
  
      if (Notification.permission === "granted") {
        new Notification("Pomodoro Timer", {
          body: "Your Pomodoro timer has ended",
        });
      }
    }
  
    return () => clearInterval(timer);
  }, [isRunning, time]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? "0" : ""}${secondsLeft}`;
  };

  const handleStart = () => {
    setIsRunning(!isRunning);
  };

  const handleAddTask = () => {
    setIsAddingTask(true);
  };

  const handleCancel = () => {
    setIsAddingTask(false);
    setNewTask('');
  };

  const handleSave= () => {
    if (newTask.trim()) {
      setTasks((prevTasks) => [
        ...prevTasks,
        { name: newTask, completed: false }
      ]); 
      setIsAddingTask(false);
      setNewTask('');
    }
  };

  const handleEditTask = (index: number) => {
    setEditIndex(index);
    setTaskEditText(tasks[index].name);
  };

  const handleSaveTask = () => {
    if (editIndex !== null) {
      const updatedTasks = tasks.map((task, index) =>
        index === editIndex ? {...task, name: taskEditText} : task
      );
      setTasks(updatedTasks);
      setEditIndex(null);
      setTaskEditText("");
    }
  };

  const handleDeleteTask = (index: number) => {
    setTasks(tasks.filter((_, i) => i !== index));
  };

  const handleCompleteTask = (index: number) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? {...task, completed: !task.completed} : task
    );
    setTasks(updatedTasks);
  };

  const handleIntervalChange = (seconds: number, mode: string) => {
    setTime(seconds);
    setIsRunning(false);
    setActiveMode(mode);
  };

  const soundMap = useMemo(() => ({
    "Forest 1": "https://pub-0d592c1e3a354cada87ed87bf8cccd4e.r2.dev/rainforest_1.mp3",
    "Forest 2": "https://pub-0d592c1e3a354cada87ed87bf8cccd4e.r2.dev/rainforest_2.mp3",
    "Construction 2": "https://pub-0d592c1e3a354cada87ed87bf8cccd4e.r2.dev/construction_1.mp3",
  }), []);

  const handleSoundChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSound = e.target.value;
    setAmbientSound(newSound);

    if (audioInstance) {
      audioInstance.pause();
      audioInstance.currentTime = 0;
    }

    const newAudio = new Audio(soundMap[newSound]);
    newAudio.loop = true;
    newAudio.play();
    setAudioInstance(newAudio);
    setIsPlaying(true);
  }, [audioInstance, soundMap]);

  useEffect(() => {
    if (isPlaying && audioInstance) {
      audioInstance.play();
    } else if (audioInstance) {
      audioInstance.pause();
    }

    return () => {
      if (audioInstance) {
        audioInstance.pause();
        audioInstance.currentTime = 0;
      }
    };
  }, [isPlaying, audioInstance]);

  const handleTogglePlayStop = () => {
    setIsPlaying(prev => !prev);
  };

  return (
    <div className="w-full h-screen flex">
      {/* Bagian Kiri */}
      <div className="flex flex-col w-2/3 p-6 gap-4">
        <h1 className="font-bold mb-3">Pomodoro Time</h1>

        {/* Timer */}
        <p className="font-bold mb-1">Main</p>
        <div className="bg-gray-900 rounded-lg p-6 mb-7 flex flex-col flex-grow">
          <div className="flex justify-center items-center mb-6 flex-wrap">
            <button
              className={`btn btn-xs mx-1 text-white text-xs flex-grow ${activeMode === 'focus' ? 'bg-zinc-950' : 'bg-purple-800'}`}
              onClick={() => handleIntervalChange(1500, 'focus')}
            >
              Focus Mode (25mins)
            </button>
            <button
              className={`btn btn-xs mx-1 text-white text-xs flex-grow ${activeMode === 'longBreak' ? 'bg-zinc-950' : 'bg-purple-800'}`}
              onClick={() => handleIntervalChange(600, 'longBreak')}
            >
              Long Break (10mins)
            </button>
            <button
              className={`btn btn-xs mx-1 text-white text-xs flex-grow ${activeMode === 'shortBreak' ? 'bg-zinc-950' : 'bg-purple-800'}`}
              onClick={() => handleIntervalChange(300, 'shortBreak')}
            >
              Short Break (5mins)
            </button>
          </div>

          <div className="text-center text-white flex-grow flex items-center justify-center">
            <span className="font-bold" style={{fontSize: "7vw"}}>
              {formatTime(time)}
            </span>
          </div>

          <div className="flex justify-center w-full">
            <button
              className="btn btn-sm bg-purple-800 text-white w-auto px-9"
              onClick={handleStart}
            >
              {isRunning ? "Pause" : "Start"}
            </button>
          </div>
        </div>

        {/* Tasks */}
        <div className="bg-transparent mb-7 flex-grow">
          <div className="flex items-center mb-4">
            <h1 className="font-bold mr-4 text-left">Tasks</h1>
            <div className="flex items-center flex-grow">
              <input
                placeholder="Tasks"
                className="max-w-[500px] w-72 input input-bordered input-sm mr-2"
              />
            </div>
          </div>

          {tasks.map((task, index) => (
            <div
              key={index}
              className="flex items-center justify-between border border-gray-300 rounded-lg p-2 mb-2"
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={task.completed}
                  onChange={() => handleCompleteTask(index)}
                />
                {editIndex === index ? (
                  <input
                    type="text"
                    value={taskEditText}
                    onChange={(e) => setTaskEditText(e.target.value)}
                    className="input input-sm input-bordered"
                  />
                ) : (
                  <span
                    style={{
                      textDecoration: task.completed ? "line-through" : "none",
                    }}
                  >
                    {task.name}
                  </span>
                )}
              </div>
              <div className="flex items-center">
                {editIndex === index ? (
                  <button onClick={handleSaveTask} className="text-green-500 mr-2">
                    Save
                  </button>
                ) : (
                  <button onClick={() => handleEditTask(index)} className="text-blue-500 mr-2">
                    Edit
                  </button>
                )}
                <button>
                  <DeleteButton onDelete={() => handleDeleteTask(index)} />
                </button>
              </div>
            </div>  
          ))}
          
          {isAddingTask ? (
            <div className="w-full bg-gray-900 border border-gray-900 rounded-lg p-4 shadow-md">
              {/* Input field yang muncul ketika "Add Task +" diklik */}
              <input
                value={newTask}
                onChange={(e) => setNewTask(e.target.value)}
                placeholder="What are you working on?"
                className="w-full h-16 p-2 border border-gray-900 rounded-md mb-2"
              />
              {/* Tombol Cancel dan Save */}
              <div className="flex justify-end">
                <button onClick={handleCancel} className="btn btn-sm mx-2 text-white bg-gray-700">
                  Cancel
                </button>
                <button onClick={handleSave} className="btn btn-sm text-white bg-purple-800">
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div className="p-4 border-dashed border rounded-lg mt-4 cursor-pointer hover:bg-purple-800 hover:border-solid transition duration-200">
              <button onClick={handleAddTask} className="w-full text-white">
                Add Task +
              </button>
            </div>
          )}
        </div>

        {/* Settings */}
        <div className="bg-transparent flex-grow">
          <p className="font-bold mb-4 text-left">Settings</p>
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <label className="mr-2">Ambient</label>
              <select
                value={ambientSound}
                onChange={handleSoundChange}
                className="select select-bordered select-xs max-w-xs"
              >
                {Object.keys(soundMap).map((sound) => (
                  <option value={sound} key={sound}>{sound}</option>
                ))}
              </select>
            </div>

            <button
              onClick={handleTogglePlayStop}
              className="btn btn-xs bg-purple-800 text-white w-auto ml-2"
            >
              {isPlaying ? "Stop" : "Play"}
            </button>
          </div>
        </div>
      </div>

      {/* Bagian Kanan */}
      <div className="w-1/3 p-6 flex flex-col">
        <p className="font-bold mb-6 mt-12">Team Timers</p>
        <div className="bg-transparent rounded-lg border-gray-300 border p-6 flex flex-col flex-grow">
          <div className="flex flex-col flex-grow gap-2">
            <div className="flex items-center justify-between border border-gray-300 rounded-lg p-2 mb-2 w-full box-border">
              <span className="font-bold">@shaddup</span>
              <span className="ml-1 flex-grow truncate">is in Focus Mode</span>
            </div>
            <div className="flex items-center justify-between border border-gray-300 rounded-lg p-2 mb-2 w-full box-border">
              <span className="font-bold">@john</span>
              <span className="ml-1 flex-grow truncate">is in Short Break</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pomodoro;