import React, { useState, useCallback, useEffect, useRef } from "react";
import { openDB } from "idb";
import { useNavigate } from "react-router-dom";

const databaseName = "devtool";
const tableName = "config";
const key = "multiuptimechecker";

interface UrlData {
  id: number; 
  url: string;
  method: string;
  name: string;
}

const MultiUptimeChecker = () => {
  const [db, setDb] = useState(null);
  const [urls, setUrls] = useState<{ [key: number]: UrlData }>({});
  const [isChecking, setIsChecking] = useState(false);
  const [results, setResults] = useState([]);
  const [link, setLink] = useState("");
  const isUserInteraction = useRef(false);
  const isParameter = useRef(false);
  const intervalRef = useRef(null);
  const navigate = useNavigate();

  // Helper function to encode JSON to Base64
  const decodeFromBase64 = (data) => {
    try {
      return JSON.parse(atob(data));
    } catch (error) {
      console.error("Failed to decode data:", error);
      return null;
    }
  };
  
  // Helper function to decode Base64 to JSON
  const encodeToBase64 = (data) => {
    return btoa(JSON.stringify(data));
  };

  const saveUrlsToDB = async (updatedUrls) => {
    if (isParameter.current) {
      setUrls(updatedUrls);
      return;
    }
    if (db) {
      await db.put(tableName, { key, data: updatedUrls });
      setUrls(updatedUrls);
    }
  };

  // Function to play error sound
  const playErrorSound = () => {
    const audio = new Audio("sounds/error.mp3");
    audio.play();
  };

  const handleAddUrl = async () => {
    const id = Date.now();
    const newUrl = { id, url: "", method: "GET", name: "" };
    const updatedUrls = { ...urls, [id]: newUrl };
    saveUrlsToDB(updatedUrls);
  };

  // Update an existing URL
  const handleUpdateUrl = (id, updatedData) => {
    if(id === "" || id === null || id === undefined) {
      return;
    };
    const updatedUrls = { ...urls, [id]: { ...urls[id], ...updatedData } };
    saveUrlsToDB(updatedUrls);
  };

  // Delete a URL
  const handleDeleteUrl = (id) => {
    const updatedUrls = { ...urls };
    delete updatedUrls[id];
    saveUrlsToDB(updatedUrls);
  };

  const handleStartStop = () => {
    if (Object.keys(urls).length === 0 && !isChecking) {
      alert("Please add new URL!");
      return;
    }    

    if (!isChecking && Object.values(urls).some(({ url, method, name }) => !url.trim() || !method.trim() || !name.trim())) {
      alert("Please fill in all URL fields before starting!");
      return;
    }
    setIsChecking((prev) => !prev);
  };

  // Check URLs status
  const checkUrlsStatus = useCallback(async () => {
    let currentResults = [];

    await Promise.all(
      Object.values(urls).map(async ({url, method, name}) => {
        try {
          const startTime = performance.now();
          const response = await fetch(url, {method});
          const status = response.status;
          const endTime = performance.now();
          const responseTime = Math.round(endTime - startTime);

          if (status !== 200) {
            if (isUserInteraction.current) {
              playErrorSound();
            }
            currentResults.push({
              url,
              status,
              responseTime,
              result: "error",
              method,
              name,
            });
          } else {
            currentResults.push({
              url,
              status,
              responseTime,
              result: "success",
              method,
              name,
            });
          }
        } catch (error) {
          if (isUserInteraction.current) {
            playErrorSound();
          }
          currentResults.push({
            url,
            status: 502,
            responseTime: "-",
            result: "error",
            method,
            name,
          });
        }
      })
    );
    setResults(currentResults);
  }, [urls]);

  useEffect(() => {
    const initDB = async () => {
      const database = await openDB(databaseName, 1, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(tableName)) {
            db.createObjectStore(tableName, { keyPath: "key" });
          }
        },
      });

      setDb(database);

      if (!isParameter.current) {
        const storedData = await database.get(tableName, key);
        if (storedData) {
          setUrls(storedData.data || {});
        }
      }
    };
    
    initDB();
  }, []);

  useEffect(() => {
    if (isChecking) {
      checkUrlsStatus();
      intervalRef.current = setInterval(checkUrlsStatus, 5000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isChecking, checkUrlsStatus]);

  useEffect(() => {
    const handleAnyInteraction = () => {
      isUserInteraction.current = true;
    };
    window.addEventListener("click", handleAnyInteraction);
  }, []);

  // Generate and encode the URLs into a shareable link
  useEffect(() => {
    const data = { urls: Object.values(urls), is_auto_start: true };
    const base64Data = encodeToBase64(data);
    setLink(`${window.location.origin}/multi-uptime-checker?data=${base64Data}`);
  }, [urls]);

  // Automatic Update URL Query
  useEffect(() => {
    if (isParameter.current) {
      const base64Data = encodeToBase64({ urls: Object.values(urls), is_auto_start: true });
      navigate(`/multi-uptime-checker?data=${base64Data}`, { replace: true });
    };
  }, [urls, isChecking, navigate]);
    
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const data = queryParams.get("data");
    if (data) {
      isParameter.current = true;
      alert("Shared content detected. Audio will not function until you interact with the UI, URLs will not be saved, and you cannot add URLs.");

      const decodedData = decodeFromBase64(data);
      if (decodedData && decodedData.urls) {
        const formattedUrls: { [key: string]: UrlData } = Array.isArray(decodedData.urls)
        ? decodedData.urls.reduce((acc, urlData: UrlData) => {
            acc[urlData.id] = urlData;
            return acc;
          }, {} as { [key: string]: UrlData })
        : (decodedData.urls as { [key: string]: UrlData });

        const hasEmptyFields = Object.values(formattedUrls).some(
          (urlData) => !urlData.id || !urlData.url || !urlData.method || !urlData.name
        );
    
        setUrls(formattedUrls);

        if (hasEmptyFields) {
          alert("The URL cannot be empty!");
          return;
        };

        if (decodedData.is_auto_start) {
          setIsChecking(true);
        };
      };
    }
  },[]);

  const renderSettingsView = () => (
    <div className="">
      <div className="grid grid-cols-1 gap-2">
        <div className="flex items-center space-x-2 text-white mb-2">
          <span className="w-1/6">Method</span>
          <span className="w-2/4">URL</span>
          <span className="w-1/6 text-left ml-4">Name</span>
        </div>
        {Object.values(urls).map(({ id, url, method, name }) => (
          <div key={id} className="flex items-center space-x-2 bg-gray-700 p-2 rounded">
            <select
              className="select select-bordered w-1/6"
              value={method}
              onChange={(e) => handleUpdateUrl(id, { method: e.target.value })}
              disabled={isChecking}
            >
              <option value="GET">GET</option>
              <option value="HEAD">HEAD</option>
              <option value="POST">POST</option>
            </select>
            <input
              type="text"
              value={url}
              placeholder="Enter URL here..."
              className="w-2/4 input input-bordered"
              onChange={(e) => handleUpdateUrl(id, { url: e.target.value })}
              disabled={isChecking}
            />
            <input
              type="text"
              value={name}
              placeholder="Enter API name..."
              className="input input-bordered w-1/6"
              onChange={(e) => handleUpdateUrl(id, { name: e.target.value })}
              disabled={isChecking}
            />
            <button className="p-2 rounded ml-2 bg-gray-800" onClick={() => handleDeleteUrl(id)} disabled={isChecking}>
              <img src="/icons/delete.svg" alt="Delete" className="w-6 h-6" />
            </button>
          </div>
        ))}
      </div>
      <div className="mt-4">
        <button className="btn btn-neutral" onClick={handleAddUrl} disabled={isChecking}>
          Add New URL
        </button>
        <button className={`${isChecking ? "btn-error" : "btn-primary"} btn ml-2`} onClick={handleStartStop}>
          {isChecking ? "Stop Checking" : "Start Checking"}
        </button>
      </div>
    </div>
  );

  const renderCopyUrlView = () => (
    <div className="">
      <textarea className="textarea textarea-bordered w-full h-32" value={link} readOnly />
      <div className="flex items-center justify-start mt-4">
        <button className="btn" onClick={() => navigator.clipboard.writeText(link)}>
          Copy URL
        </button>
        <p className="text-white ml-6">You can directly access the address to restore the dashboard</p>
      </div>
    </div>
  );

  return (
    <div className="flex w-full h-full bg-gray-800">
      <div className="w-full p-4">
        <h1 className="text-xl font-bold mb-4 text-white">
          Multi Uptime Checker
        </h1>
        {/* Display API check results */}
        <div className="grid grid-cols-3 gap-4 mt-4">
          {results.map(({ url, status, responseTime, result, method, name }, index) => {
            let bgColor = result === "success" ? (responseTime < 300 ? "bg-green-700" : "bg-yellow-500") : "bg-red-700";
            return (
              <div
                key={index}
                className={`p-4 rounded shadow ${bgColor}`}
              >
                <h2 className="font-bold text-white truncate overflow-hidden whitespace-nowrap">
                  {name} ({status})
                </h2>
                <p className="text-2xl text-white truncate overflow-hidden whitespace-nowrap">{responseTime} ms</p>
                <p className="text-gray-400 truncate overflow-hidden whitespace-nowrap">
                  {method} {url}
                </p>
              </div>
            );
          })}
        </div>
        <div className="mt-4">
          <div role="tablist" className="tabs tabs-lifted w-full">
            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Settings"
              defaultChecked
            />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              {renderSettingsView()}
            </div>

            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Copy URL"
            />
            <div role="tabpanel" className="tab-content bg-base-100 border-base-300 rounded-box p-6">
              {renderCopyUrlView()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiUptimeChecker;
