import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
const SMTPMailDev: React.FC = () => {
  const [emails, setEmails] = useState([
    {
      id: 1,
      sender: "sender@example.com",
      subject: "Reset Password",
      time: "3 seconds ago",
    },
    {
      id: 2,
      sender: "sender@example.com",
      subject: "Email Subject lorem ipsum dolor...",
      time: "1 minute ago",
    },
  ]);

  // State to store the currently selected email, defaulting to the first email if available, otherwise null.
  const [selectedEmail, setSelectedEmail] = useState(emails[0] || null);

  // State to store the input for a new sender.
  const [newSender, setNewSender] = useState("");

  // Reference to the modal dialog element for direct manipulation of modal visibility.
  const modalRef = useRef<HTMLDialogElement>(null);

  // State to store the last update time for the component, initialized with a default value.
  const [lastUpdate, setLastUpdate] = useState("3 seconds ago");

  // useEffect to update the lastUpdate state every 3 seconds with a real-time timestamp.
  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdate("Just now");
    }, 3000); // Update interval in milliseconds

    return () => clearInterval(interval); // Cleanup function to clear the interval when the component unmounts
  }, []);

  // Function to delete an email from the list by its id.
  // If the deleted email is currently selected, updates the selected email to the next available one or sets it to null if none are left.
  const deleteEmail = (id: number) => {
    setEmails(emails.filter((email) => email.id !== id));
    if (selectedEmail?.id === id) {
      setSelectedEmail(emails.length > 1 ? emails[1] : null);
    }
  };

  // Function to clear all emails from the list and reset the selected email to null.
  const clearEmails = () => {
    setEmails([]);
    setSelectedEmail(null);
  };

  // Function to open the modal dialog by calling the showModal method on the dialog element.
  const openModal = () => {
    modalRef.current?.showModal();
  };

  // Function to close the modal dialog by calling the close method on the dialog element.
  const closeModal = () => {
    modalRef.current?.close();
  };

  // Function to handle click events outside the modal, closing the modal if the click target matches the modal element.
  const handleOutsideClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    if (modalRef.current && event.target === modalRef.current) {
      closeModal();
    }
  };

  // Function for add new email to emails list.
  const addEmail = () => {
    // Validation: ensure the input is not empty before proceeding.
    if (newSender.trim() === "") return;

    // Create a new email object with a unique id, default subject, and current timestamp.
    const newEmail = {
      id: emails.length + 1, // Generate a new unique id based on the current list length.
      sender: newSender, // Use the current input value for the sender.
      subject: "New email subject", // Default subject for the email.
      time: "Just now", // Default time representing the current moment.
    };

    // Add the new email to the existing list of emails.
    setEmails([...emails, newEmail]);

    // Reset the sender input field after adding the email.
    setNewSender("");
  };

  // Function to fetch email data asynchronously from a specified API endpoint.
  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.smtpbucket.com/emails?recipient=test%40example.com&limit=100"
      );
      console.log("API response:", response.data); 
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return []; 
    }
  };
  
  // useCallback hook to memoize the fetchData function, ensuring it only updates when dependencies change.
  // fetchData calls getData to fetch email data and updates the state if data is received.
  const fetchData = useCallback (async () => {
    const data = await getData();
    if (data && Array.isArray(data.emails)) {
      setEmails(data.emails); 
    }
  }, []);
  
  useEffect(() => {
  fetchData();

  const interval = setInterval(() => {
    console.log("Fetching data every 3 seconds");
    fetchData();
  }, 3000);
  return () => clearInterval(interval);
  }, [fetchData]);

  return (
    <div className="p-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold">SMTP Mail Dev</h1>
      </div>

      {/* Button settings and clear */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-2">
          <button className="btn btn-sm" onClick={openModal}>
            Settings
          </button>
          <button className="btn btn-sm" onClick={clearEmails}>
            Clear
          </button>
          <span className="ml-2 text-sm">Last update {lastUpdate}</span>
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="recipient" className="font-bold text-sm">
            Recipient:
          </label>
          <input
            type="text"
            id="recipient"
            placeholder="me@example.com"
            className="input input-bordered input-sm w-full max-w-xs"
            style={{ color: "gray" }}
          />
        </div>
      </div>

      <div className="flex space-x-4">
        {/* Left column: Email list with a large border surrounding it */}
        <div className="w-1/4 border p-4 h-96 overflow-y-auto rounded-lg">
          <div className="h-full">
            {/* Email list with a border around each item */}
            {emails.length > 0 ? (
      emails.map((email) => (
        <div
          key={email.id}
          className={`p-2 mb-2 border border-gray-300 rounded-lg flex justify-between items-center cursor-pointer ${
            selectedEmail?.id === email.id
              ? "bg-gray-200 bg-opacity-50"
              : "hover:bg-gray-100"
          }`}
          onClick={() => setSelectedEmail(email)}
        >
          <div className="text-xs">
            <p className="font-bold">{email.sender}</p>
            <p>{email.subject}</p>
          </div>
          <div className="flex flex-col items-center space-y-1">
            <p className="text-right text-[10px] text-gray-500 whitespace-nowrap">
              {email.time}
            </p>
            <div className="w-full flex justify-end">
              <button onClick={() => deleteEmail(email.id)}>
                <img
                  src="/icons/delete.svg"
                  alt="delete"
                  className="h-3 w-3 text-gray-500 hover:text-gray-500"
                />
              </button>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p className="text-center">No emails</p>
    )}
  </div>
</div>

        {/* Right column: Email detail */}
        <div className="w-3/4 p-4 border rounded-lg">
          {selectedEmail ? (
            <div>
              <div className="mb-4">
                <p>
                  <strong>Sender:</strong> {selectedEmail.sender}
                </p>
                <p>
                  <strong>Subject:</strong> {selectedEmail.subject}
                </p>
                <p>
                  <strong>Timestamp:</strong> {selectedEmail.time}
                </p>
              </div>
              <div className="-mx-4 border-t my-4"></div>
              <div className="h-64 flex items-center justify-center">
                <p>Email Content Here</p>
              </div>
            </div>
          ) : (
            <p className="text-center">No email selected</p>
          )}
        </div>
      </div>

      {/* Modal for settings */}
      <dialog
        ref={modalRef}
        className="modal z-50"
        onClick={handleOutsideClick}
      >
        <div className="modal-box">
          <h3 className="text-black text-lg">Settings</h3>

          {/* Tablist component using DaisyUI for styled tabs */}
          <div role="tablist" className="tabs tabs-lifted">
            <input
              type="radio"
              name="my_tabs_2"
              role="tab"
              className="tab"
              aria-label="Sender"
              defaultChecked
            />
            <div
              role="tabpanel"
              className="tab-content bg-base-100 border-base-300 rounded-box p-6"
            >
              {/* Input Sender */}
              <div className="flex items-center mb-4">
                <input
                  type="text"
                  className="input input-bordered flex-grow mr-2"
                  placeholder="sender@example.com"
                  value={newSender}
                  onChange={(e) => setNewSender(e.target.value)} // Update state input
                  style={{ color: "gray" }}
                />
                <button
                  className="btn btn-ghost"
                  onClick={() => setNewSender("")} // Clear input on click
                >
                  <img
                    src="/icons/delete.svg"
                    alt="delete"
                    className="h-5 w-5"
                  />
                </button>
              </div>

              {/* Add button below the input field for the sender */}
              <div className="mt-2">
                <button className="btn btn-sm">Add</button>
              </div>

              {/* Save button to trigger the addEmail function */}
              <div className="flex justify-start mt-4">
                <button className="btn btn-sm" onClick={addEmail}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default SMTPMailDev;

