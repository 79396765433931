import React, { useState } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const MapComponent = () => {
  const [positions, setPositions] = useState({
    pointA: null,
    pointB: null,
  });
  const [distance, setDistance] = useState(null);
  const [inputA, setInputA] = useState('');
  const [inputB, setInputB] = useState('');

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; 
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const handleInputChange = (setter, value) => {
    setter(value);
    const coords = value.split(',').map((coord) => parseFloat(coord.trim()));

    if (
      coords.length === 2 &&
      !isNaN(coords[0]) &&
      !isNaN(coords[1]) &&
      coords[0] >= -90 &&
      coords[0] <= 90 &&
      coords[1] >= -180 &&
      coords[1] <= 180
    ) {
      setPositions((prevPositions) => {
        const newPositions = { ...prevPositions };
        if (setter === setInputA) {
          newPositions.pointA = coords;
        } else {
          newPositions.pointB = coords;
        }

        if (newPositions.pointA && newPositions.pointB) {
          const distanceCalculated = calculateDistance(
            newPositions.pointA[0],
            newPositions.pointA[1],
            newPositions.pointB[0],
            newPositions.pointB[1]
          );
          setDistance(distanceCalculated.toFixed(2));
        } else {
          setDistance(null);
        }

        return newPositions;
      });
    }
  };

  return (
    <div className="w-full relative h-full overflow-hidden">
      <h1 className="font-bold m-6">Distance Calculator</h1>
      <div className="flex justify-between items-center mx-6 gap-6">
        <div className="text-center flex items-center gap-6 w-1/3">
          <span className="whitespace-nowrap">Point A</span>
          <input
            type="text"
            placeholder="Latitude, Longitude"
            value={inputA}
            onChange={(e) => handleInputChange(setInputA, e.target.value)}
            className="text-white input input-sm input-bordered w-full"
          />
        </div>
        <div className="text-center flex items-center gap-6 w-1/3">
          <span className="whitespace-nowrap">Point B</span>
          <input
            type="text"
            placeholder="Latitude, Longitude"
            value={inputB}
            onChange={(e) => handleInputChange(setInputB, e.target.value)}
            className="text-white input input-sm input-bordered w-full"
          />
        </div>
        <div className="text-center flex items-center gap-6 w-1/3">
          <span className="whitespace-nowrap">Distance</span>
          <input 
          type="text"
          placeholder="In KM"
          value={distance}
          readOnly
          className='text-white input input-bordered input-sm w-full'
          />
        </div>
      </div>

      <div className="w-full h-[calc(100vh-150px)] box-border p-6 rounded-lg">
        <MapContainer center={[51.505, -0.09]} zoom={13} className="w-full h-full rounded-lg overflow-hidden shadow-inner">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {positions.pointA && <Marker position={positions.pointA}></Marker>}
          {positions.pointB && <Marker position={positions.pointB}></Marker>}
          {positions.pointA && positions.pointB && (
            <Polyline positions={[positions.pointA, positions.pointB]} color="red" />
          )}
        </MapContainer>
      </div>
    </div>
  );
};

export default MapComponent;
