// ============================
// Import Dependencies
// ============================
import React, { useEffect, useState } from "react";
import WorkInProgressBadge from "../components/WorkInProgressBadge";
import { observer } from "mobx-react-lite";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import promptsJSON from "../assets/json/llm_code_converter_prompt.json";

// Syntax Highlighters for supported languages
import { javascriptLanguage } from "@codemirror/lang-javascript";
import { typescriptLanguage } from "@codemirror/lang-javascript";
import { jsonLanguage } from "@codemirror/lang-json";
import { phpLanguage } from "@codemirror/lang-php";
import { markdownLanguage } from "@codemirror/lang-markdown";
import { htmlLanguage } from "@codemirror/lang-html";
import { useStore } from "..";
import toast from "react-hot-toast";

// ============================
// Utility Functions
// ============================

/**
 * Utility to map a language name to its corresponding CodeMirror language object.
 * @param {string} name - Name of the language (e.g., "json", "php").
 * @returns {any} - CodeMirror language object for the provided language.
 */

const getLangs = (name: string) => {
  const langs: { [key: string]: any } = {
    json: jsonLanguage,
    php: phpLanguage,
    javascript: javascriptLanguage,
    typescript: typescriptLanguage,
    markdown: markdownLanguage,
    html: htmlLanguage,
  };
  return langs[name];
};

// ==============
// Main Component
// ==============

/**
* LLMCodeConverter Component:
* A tool for converting code snippets from one format/language to another using LLM (Language Model).
* Features include syntax highlighting, prompt selection, and integration with LLM API.
*/

const LLMCodeConverter = observer(() => {
  // ========================
  // State Management (Hooks)
  // ========================
  const { llmStore } = useStore(); // Access LLM store for managing global LLM states
  const {
    setModelIndex,
    llmHandleSubmit,
    modelIndex,
    availableModels,
    isLlmLoading,
    executionTime,
    resultText,
  } = llmStore;

  const prompts = promptsJSON;
  const [selectedPromptIndex, setSelectedPromptIndex] = useState<number>(0);
  const [prompt, setPrompt] = useState<string>(prompts[0]?.prompt || "");
  const [inputCode, setInputCode] = useState<string>(atob(prompts[0]?.sample_code || "").toString());
  const [localResult, setLocalResult] = useState("");
  const [localExecutionTime, setLocalExecutionTime] = useState(executionTime);
  const [localIsLlmLoading, setIsLocalLlmLoading] = useState(isLlmLoading)

  // =======
  // Effects
  // =======

  /**
  * Sync resultText from the global store to the local `localResult` state.
  * This ensures the component reflects the latest LLM output.
  */
  useEffect(() => {
    setLocalResult(resultText);
  }, [resultText]);

  /**
  * Sync executionTime from the global store to the local `localExecutionTime` state.
  * This ensures the component reflects the latest LLM output.
  */
  useEffect(() => {
    setLocalExecutionTime(executionTime);
  }, [executionTime]);

  /**
  * Sync isLlmLoading from the global store to the local `localIsLlmLoading` state.
  * This ensures the component reflects the latest LLM output.
  */
  useEffect(() => {
    setIsLocalLlmLoading(isLlmLoading);
  }, [isLlmLoading]);

  // ==============
  // Event Handlers
  // ==============

  /**
  * Handles changes in the input editor.
  * @param {string} value - New input value.
  */
  const handleInputCodeChange = (value) => {
    setInputCode(value);
  };

  /**
  * Handles prompt selection by the user.
  * Updates the prompt, input code, and resets the result.
  * @param {number} index - Index of the selected prompt.
  */
  const handlePromptChange = (index: number) => {
    setSelectedPromptIndex(index);
    setPrompt(prompts[index]?.prompt || "");
    setInputCode(atob(prompts[index]?.sample_code || "").toString());
    setLocalResult("");
  };

  /**
  * Submits the selected prompt and input code to the LLM API.
  * Replaces the placeholder `{input}` in the prompt with the user's input.
  */
  const handleSubmit = () => {
    if (inputCode === null || inputCode === "") {
      toast.error("Please insert input code");
      return;
    }

    if (prompt === null || prompt === "") {
      toast.error("Please insert prompt");
      return;
    }

    llmHandleSubmit({
      prompt: prompt.replace("{input}", inputCode || ""),
    });
  };

  /**
  * Reads text from the clipboard and sets it as the input code.
  */
  const handleClipboard = async () => {
    try {
      const value = await navigator.clipboard.readText();
      setInputCode(value);
    } catch (error) {
      toast.error("Failed to read clipboard", error);
    }
  };

  /**
  * Copies the LLM result to the clipboard.
  */
  const handleCopy = () => {
    navigator.clipboard.writeText(resultText);
  }

  /**
  * Updates the selected LLM model based on user selection.
  * @param {number} index - Index of the selected model.
  */
  const handleModelChange = (index: number) => {
    setModelIndex(index);
  };

  // ================
  // Render Component
  // ================

  return (
    <div className="flex">
      <div className="w-full mx-6">
        <div className="w-full flex items-center font-bold mb-3 gap-3">
          <div className="title">
            Magic Code Converter
          </div>
          <WorkInProgressBadge/>
        </div>
        <div className="grid grid-cols-2 gap-4">

          {/* Input Code Section */}
          <div>
            <div className="mb-2">
              <div className="grid grid-cols-2 gap-0">
                <div>
                  <div className="inline-block mr-2">Input Code</div>
                  <button className="btn btn-xs" onClick={handleClipboard}>
                    Clipboard
                  </button>
                </div>
                <div className="justify-end">
                  <select
                    className="select select-xs select-bordered w-full btn btn-xs mx-0"
                    value={selectedPromptIndex}
                    onChange={(e) =>
                      handlePromptChange(parseInt(e.target.value))
                    }
                  >
                    {prompts.map((item, index) => (
                      <option key={`prompt-${index}`} value={index}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <CodeMirror
              className="bg-gray-900 w-full border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 overflow-hidden min-h-[84vh] max-h-[84vh]"
              placeholder="Enter input here"
              value={inputCode}
              onChange={(value) => handleInputCodeChange(value)}
              height="84vh"
              theme={dracula}
              extensions={[
                EditorView.editable.of(true),
                EditorView.lineWrapping,
                getLangs(prompts[selectedPromptIndex]?.sample_lang ?? "markdown"),
              ]}
            />
          </div>

          {/* Custom Input Prompt Section */}
          <div>
            <div className="flex justify-between">
              <div>Custom Prompt</div>
              <div className="inline-block ml-auto">
                <select
                  className="select select-xs select-bordered w-full"
                  value={modelIndex}
                  onChange={(e) =>
                    handleModelChange(parseInt(e.target.value))
                  }
                >
                  {availableModels.map((model, index) => (
                    <option key={`model-${index}`} value={index}>
                      {model}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <textarea
              rows={3}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              className="mt-2 text-xs bg-gray-900 w-full p-1 mb-2 border border-gray-700 rounded-md focus:outline-none focus:ring focus:border-blue-500 text-white"
            ></textarea>
            <div>

              {/* Result Section */}
              <div className="flex justify-between mb-2">
                <div>Result</div>
                <div className="inline-block ml-auto">
                  <button
                    className="btn btn-xs mx-1"
                    disabled={isLlmLoading}
                    onClick={handleSubmit}
                  >
                    {localIsLlmLoading ? (
                      <>Loading {(localExecutionTime / 1000).toFixed(1)}s</>
                    ) : (
                      <>Convert</>
                    )}
                  </button>
                </div>
                <button className="btn btn-xs mx-1" onClick={() => { handleCopy() }}>Copy</button>
              </div>
              <CodeMirror
                className="bg-gray-900 w-full border border-gray-700 overflow-hidden rounded-md focus:outline-none focus:ring focus:border-blue-500 min-h-[68.9vh] max-h-[68.9vh]"
                value={localResult}
                height="68.9vh"
                theme={dracula}
                extensions={[
                  EditorView.editable.of(true),
                  EditorView.lineWrapping,
                  getLangs(
                    prompts[selectedPromptIndex]?.result_lang ?? "markdown"
                  ),
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default LLMCodeConverter;
